import React, { useEffect, useMemo, useState } from "react";
import { BigNumber, ethers } from "ethers";
import { Contract } from "@ethersproject/contracts";

import "./index.sass";
import { useWeb3React } from "@web3-react/core";
import { ABI_ARRAY } from "../../../contracts/contract";
import { APP_ENVIRONMENTS } from "../../../configs/env";
import { CONSTANTS } from "../../../configs/constants";
import { MessageClaimBox } from "../../shared/MessageClaimBox";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setBalanceToken } from "../../../redux/actions/home.actions";

export const SectionTop = () => {
  const [amount, setAmount] = useState(1);
  const [price, setPrice] = useState(BigNumber.from(0));
  const [maxSaleAmount, setMaxSaleAmount] = useState(500);
  const [released, setReleased] = useState(6);
  const [accountETHBalance, setAccountETHBalance] = useState(0);
  const [claimStatus, setClaimStatus] = useState("");
  const [claimMessage, setClaimMessage] = useState("");
  const [isOpenClaimBox, setIsOpenClaimBox] = useState(false);
  const [transactionHash, setTransactionHash] = useState("");

  const web3ReactContext = useWeb3React();
  const { account, library } = web3ReactContext;

  const { tokenBalance } = useSelector((state) => state.home);
  const dispatch = useDispatch();

  const myContract = useMemo(() => {
    if (library) {
      return new Contract(
        APP_ENVIRONMENTS.CONTRACTS_ADDRESS,
        ABI_ARRAY,
        library.getSigner()
      );
    }
  }, [library]);

  useEffect(() => {
    if (account) {
      getReleased();

      library
        .getBalance(account)
        .then((balance) => {
          const balanceValue = ethers.utils.formatEther(balance);
          setAccountETHBalance(Number(Number(balanceValue).toFixed(4)));
        })
        .catch(() => {
          setAccountETHBalance(0);
        });

      (async () => {
        try {
          const res = await Promise.all([
            myContract.price(),
            myContract.maxSaleAmount(),
          ]);

          setPrice(res[0]);
          setMaxSaleAmount(Number(res[1]));
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [account]);

  const getBalance = async () => {
    try {
      const balance = await myContract.balanceOf(account);
      dispatch(setBalanceToken(Number(balance)));
    } catch (error) {
      console.log(error);
    }
  };

  const getReleased = async () => {
    try {
      const _released = await myContract.released();
      setReleased(Number(_released));
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    let { value } = e.target;

    if (isNaN(Number(value))) {
      return;
    }
    setAmount(Number(value));
  };

  const handleClaim = async () => {
    const _price = Number(ethers.utils.formatEther(price));

    if (!account) {
      return toast.error(CONSTANTS.messageToast.error_connect_wallet);
    }

    if (accountETHBalance < amount * _price) {
      return toast.error(CONSTANTS.messageToast.error_balance_not_enough);
    }

    let estimateGas = ethers.BigNumber.from(String(150000));

    try {
      setIsOpenClaimBox(true);
      setClaimStatus("sending");

      estimateGas = await myContract.estimateGas.mint(amount, {
        value: price.mul(amount),
      });

      const txn = await (
        await myContract.mint(amount, {
          value: price.mul(amount),
          gasLimit: estimateGas.mul(150).div(100),
        })
      ).wait();

      if (txn && txn.transactionHash) {
        setClaimStatus("success");
        getBalance();
        getReleased();
        setTransactionHash(txn.transactionHash);
      }
    } catch (err) {
      const errMsg = err.error
        ? err.error.message
        : err.message || CONSTANTS.messageToast.unknown_error;

      setClaimStatus("error");
      setClaimMessage(errMsg);
    }
  };

  return (
    <>
      <div className="section section-top">
        <div className="content d-flex justify-content-center align-items-center">
          <img className="text-banner" src="/images/text-banner.png" />
          <div className="buy-dddb d-flex flex-column justify-content-center align-items-center">
            {/* <p className="font-montserrat-extra-bold text-shadow color-red-pink number-left">
              Presale: {released}/{maxSaleAmount}
            </p> */}
            {/* {tokenBalance < 5 && (
              <input
                pattern="[0-9]*"
                type="text"
                value={amount}
                className="input-amount font-montserrat-extra-bold fsz-3"
                onChange={(e) => handleInputChange(e)}
              />
            )} */}

            {/* <p className="msg-claim-over font-montserrat-bold color-red-pink">
              You own: {tokenBalance || 0}/5
            </p> */}

            {/* {tokenBalance < 5 && (
              <button
                className="btn-gradient btn-mint font-montserrat-bold"
                disabled={
                  !amount ||
                  amount < 1 ||
                  amount > 5 ||
                  amount + tokenBalance > 5
                }
                onClick={() => handleClaim()}
              >
                MINT
              </button>
            )} */}

            <a
              target="_blank"
              href="https://dddb-assets.s3.ap-southeast-1.amazonaws.com/docs/DDDBLitePaper.pdf"
              className="font-montserrat-extra-bold fsz-11 text-shadow color-red-pink link-download"
            >
              Download Deck
            </a>
          </div>
        </div>
      </div>
      <MessageClaimBox
        isOpenClaimBox={isOpenClaimBox}
        close={() => setIsOpenClaimBox(false)}
        status={claimStatus}
        msg={claimMessage}
        transactionHash={transactionHash}
      />
    </>
  );
};
