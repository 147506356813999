import React from "react";
import ReactGA from "react-ga";

import { Header } from "../header/header.jsx";
import { Footer } from "../footer/footer.jsx";
import { SectionTop } from "./sectionTop";
import "./index.sass";
import { SectionAbout } from "./sectionAbout/index.jsx";
import { SectionRoadmap } from "./sectionRoadmap/index.jsx";
import { SectionFeatured } from "./sectionFeatured/index.jsx";
import { SectionTeam } from "./sectionTeam/index.jsx";
import { SectionDDDB } from "./sectionDDDB/index.jsx";
import { SectionCommunity } from "./sectionCommunity/index.jsx";
import { SectionFaq } from "./sectionFaq/index.jsx";

ReactGA.initialize("UA-223382853-1");
ReactGA.pageview(window.location.pathname + window.location.search);

export const Home = () => {
  return (
    <>
      <Header />
      <div className="home">
        <div className="wrapper">
          <SectionTop />
          <SectionAbout />
          <SectionRoadmap />
          <SectionDDDB />
          <SectionFeatured />
          <SectionTeam />
          <SectionFaq />
          <SectionCommunity />
        </div>
      </div>
      <Footer />
    </>
  );
};
