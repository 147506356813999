import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setRefRoadmap } from "../../../redux/actions/home.actions.js";

import "./index.sass";

export const SectionRoadmap = () => {
  const sectionRoadmapRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRefRoadmap(sectionRoadmapRef));
  }, [dispatch]);

  return (
    <>
      <div className="section section-roadmap" ref={sectionRoadmapRef}>
        <div className="container">
          <div className="roadmap d-flex flex-column justify-content-center align-items-center">
            <div className="font-montserrat-extra-bold fsz-16 color-red-pink mb-3">
              ROADMAP
            </div>
            <div className="timeline flex-column justify-content-center align-items-center">
              <div className="item item-1 d-flex justify-content-start align-items-start mb-7">
                <div className="percent p-1"></div>
                <div className="desc">
                  <h3 className="font-montserrat-bold fsz-14 mb-1 color-violet">
                    April 2022
                  </h3>
                  <p className="font-montserrat-semi-bold fsz-9">
                  Launch of NFT and Community <br /><br />
                  • Onboarding Training Partners and Service Providers within DCC Ecosystem<br />
                  • Issuance of EA to all NFT Holders by 1st week of May
                  </p>
                </div>
              </div>

              <div className="item item-2 d-flex justify-content-start align-items-start mb-7">
                <div className="percent p-2"></div>
                <div className="desc">
                  <h3 className="font-montserrat-bold fsz-14 mb-1 color-violet">
                    Q3 2022
                  </h3>
                  <p className="font-montserrat-semi-bold fsz-9">
                  Expansion of DDDB Community Features<br /> <br />
                  • Unlock membership tier perks<br />
                  • Unlock features such as community voting for project proposals
                  </p>
                </div>
              </div>

              <div className="item item-3 d-flex justify-content-start align-items-start mb-7">
                <div className="percent p-3"></div>
                <div className="desc">
                  <h3 className="font-montserrat-bold fsz-14 mb-1 color-violet">
                    2023
                  </h3>
                  <p className="font-montserrat-semi-bold fsz-9">
                  Unlock DCC Metaverse Trading Academy<br /> <br />
                  • Creation of DCC Metaverse Trading Academy<br />
                  • First DCC Trading Workshop in Metaverse by Anchor Training Providers
                  </p>
                </div>
              </div>

              <div className="item item-4 d-flex justify-content-start align-items-start mb-7">
                <div className="percent p-4"></div>
                <div className="desc">
                  <h3 className="font-montserrat-bold fsz-14 mb-1 color-violet">
                    2024
                  </h3>
                  <p className="font-montserrat-semi-bold fsz-9">
                  Technology Development<br /> <br />
                  • Focusing on Web3 Development<br />
                  • Explore utility that adds value to our community
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
