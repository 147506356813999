export const CONSTANTS = {
  media_small_screen: "(max-width: 850px)",
  messageToast: {
    error_connect_wallet: "Please connect your wallet to continue.",
    error_ethereum_main_network: "Please connect to Ethereum Main Network.",
    error_ethereum_test_network: "Please connect to Rinkeby Test Network.",
    error_metamask_plugin:
      "Non-Ethereum browser detected. Please install MetaMask plugin.",
    error_balance_not_enough: "Your balance is not enough.",
    unknown_error: "An error has occurred, please try again.",
    switch_desktop: "Please switch the desktop version to continue.",
    require_use_metamask_browser:
      "Please switch to Metamask browser to continue.",
  },
};

export const SALE_STATUS = {
  private_sale: 0,
  public_sale: 1,
};

export const NETWORK_DATA = {
  mainnet: {
    chainId: 1,
    ethChainId: "0x1",
    messageErr: CONSTANTS.messageToast.error_ethereum_main_network,
  },
  testnet: {
    chainId: 4,
    ethChainId: "0x4",
    messageErr: CONSTANTS.messageToast.error_ethereum_test_network,
  },
};
