import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { CONSTANTS } from "../../configs/constants.js";
import { APP_ENVIRONMENTS } from "../../configs/env.js";
import { Footer } from "../footer/footer.jsx";
import { Header } from "../header/header.jsx";

import "./index.sass";

export const Member = () => {
  const web3ReactContext = useWeb3React();
  const { account, library } = web3ReactContext;
  const { tokenBalance } = useSelector((state) => state.home);

  const [mt4Registered, setMt4Registered] = useState(false);
  const [mt4Number, setMt4Number] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputMT4Number = (e) => {
    let { value } = e.target;

    if (isNaN(Number(value)) || Number(value) > 999999999999) {
      return;
    }

    setMt4Number(value);
  };

  useEffect(() => {
    if (account) {
      handleCheckEnteredMT4();
    }
  }, [account]);

  const handleCheckEnteredMT4 = async () => {
    axios
      .get(`${APP_ENVIRONMENTS.MT4_API_URL}/mt4/account/${account}`)
      .then(function (res) {
        console.log(res.data);
        if (res.data) {
          setMt4Number(Number(res.data?.content || 1));
          setMt4Registered(true);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const handleMT4Register = async () => {
    let params;
    const currentTime = moment.utc().format("YYYY-MM-DD HH:mm");

    const signer = library.getSigner();

    await signer
      .signMessage(`dddb_${currentTime}`)
      .then((signature) => {
        params = {
          account_address: account,
          message: currentTime,
          signature,
          content: Number(mt4Number),
        };
      })
      .catch((err) => {
        const errMsg = err.error
          ? err.error.message
          : err.message || CONSTANTS.messageToast.unknown_error;

        toast.error(errMsg);
      });

    if (params) {
      setIsLoading(true);
      axios
        .post(`${APP_ENVIRONMENTS.MT4_API_URL}/mt4`, params)
        .then(function (res) {
          toast.success("Register Success!");
          setIsLoading(false);

          setTimeout(() => {
            window.location.reload();
          }, 300);
        })
        .catch(function (error) {
          console.log("error", error);
        });
    }
  };

  return (
    <>
      <>
        <Header />
        <div className="member-page">
          <div className="container">
            <div className="content">
              {!tokenBalance && (
                <Link
                  to="/"
                  className="please-buy font-montserrat-bold fsz-14 mb-1 color-violet mb-3"
                >
                  Please buy a token to access this area.
                </Link>
              )}

              {tokenBalance > 0 && (
                <div className="enter-mt4 d-flex flex-column justify-content-center align-items-center mb-2">
                  <h3 className="font-montserrat-semi-bold fsz-14 color-red-pink">
                    {mt4Registered ? "Your MT4 Number" : "Store MT4 Number"}
                  </h3>
                  <input
                    type="text"
                    className="font-montserrat-semi-bold"
                    value={mt4Number}
                    onChange={(e) => handleInputMT4Number(e)}
                  />
                  <button
                    className="btn-enter btn-gradient font-montserrat-bold"
                    onClick={() => handleMT4Register()}
                    disabled={!mt4Number || Number(mt4Number) <= 0}
                  >
                    {isLoading
                      ? "Submitting..."
                      : mt4Registered
                      ? "Update"
                      : "Submit"}
                  </button>
                </div>
              )}

              {/*tokenBalance > 0 && (
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <a href={`${APP_ENVIRONMENTS.MT4_API_URL}/mt4/all`}>
                    <button className="btn-download-mt4 btn-gradient font-montserrat-bold">
                      Download MT4 Data
                    </button>
                  </a>
                </div>
              )*/}

              {
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <a href="https://dddb-assets.s3.ap-southeast-1.amazonaws.com/MMAGX_V22.04.25.ex4">
                    <button className="btn-download-mt4 btn-gradient font-montserrat-bold">
                      Download EA 1
                    </button>
                  </a>
                </div>
              }
              <div>
                <a
                  href="https://discord.gg/J3wxdQrw32"
                  target="_blank"
                  rel="noreferrer"
                  className="discord-vip font-montserrat-bold"
                >
                  Access Members Only Discord
                </a>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    </>
  );
};
