import React, { useState } from "react";
import { map } from "lodash";
import "./index.sass";

export const SectionDDDB = () => {
  return (
    <>
      <div className="section section-dddb bg-red-pink">
        <div className="container">
          <div className="content d-flex justify-content-space-between align-items-center">
            <div className="left-image text-center">
              <img src="/images/about.png" alt="" />
            </div>
            <div className="about">
              <h3 className="title font-montserrat-black fsz-16 color-yellow mb-3">
                WHO IS DUDE D DOUGH BOY?
              </h3>
              <p className="font-avenir-next-regular fsz-12 color-white">
                Dude D Dough Boy is a little hard worker that does his best to
                bring the dough home to you. He’s a tough cookie, and he is a
                breadwinner. Anyone can bring in an income, but he makes it
                easier for you, so that you can kick back, relax, sip your
                coffee, and do whatever you please. He changes up his looks so
                that things won’t be stale, and when you’re going through a hard
                time, he makes things batter.
                <br />
                <br />
                Hardworking, silent, persevering, and relentless. These are the
                traits that a good worker has, and Dude D Dough Boy aims to be
                just that. He works hard to be a breadwinner so you can play
                harder; to live life without worries, that tomorrow is a better
                day. This little kid will bring home the dough, and not just
                dough fritters with a coffee, but baguettes and pretzels. His
                hard work and dedication is his bread and butter, and he dresses
                to impress. With his little hat, he could be dressed for work or
                play or just to chill out. DDDB is a cheery worker, because
                he’ll never wake up on the wrong side of the bread. Under the
                hat might be a cup of coffee, or freshly toasted bread.
                Sometimes, it could be a lottery ticket! All he’s waiting for is
                for you to whisk him away and all your worries will be toast.
                <br />
                <br />
                Bring him home today; he’ll be your golden goose and fill your
                pockets with dough.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
