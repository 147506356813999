import {
  SET_BALANCE_TOKEN,
  SET_REF_ABOUT,
  SET_REF_ROADMAP,
  SET_REF_TEAM,
} from "../constants/home.action.types";

export const setRefAbout = (payload) => {
  return {
    type: SET_REF_ABOUT,
    payload,
  };
};

export const setRefRoadmap = (payload) => {
  return {
    type: SET_REF_ROADMAP,
    payload,
  };
};

export const setRefTeam = (payload) => {
  return {
    type: SET_REF_TEAM,
    payload,
  };
};

export const setBalanceToken = (payload) => {
  return {
    type: SET_BALANCE_TOKEN,
    payload,
  };
};
