import {
  SET_BALANCE_TOKEN,
  SET_REF_ABOUT,
  SET_REF_ROADMAP,
  SET_REF_TEAM,
} from "../constants/home.action.types";

const home = (state = {}, action) => {
  switch (action.type) {
    case SET_REF_ABOUT:
      return {
        ...state,
        refAbout: action.payload ? action.payload : null,
      };

    case SET_REF_ROADMAP:
      return {
        ...state,
        refRoadmap: action.payload ? action.payload : null,
      };

    case SET_REF_TEAM:
      return {
        ...state,
        refTeam: action.payload ? action.payload : null,
      };

    case SET_BALANCE_TOKEN:
      return {
        ...state,
        tokenBalance: action.payload ? action.payload : 0,
      };

    default:
      return state;
  }
};

export default home;
