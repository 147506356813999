import { Home } from "./components/home";
import { Member } from "./components/member";

export const ROUTERS = [
  {
    name: "Home",
    path: "/",
    component: Home,
  },
  {
    name: "Member",
    path: "/member",
    component: Member,
  },
];
