import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setRefTeam } from "../../../redux/actions/home.actions.js";

import "./index.sass";

export const SectionTeam = () => {
  const sectionTeamRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRefTeam(sectionTeamRef));
  }, [dispatch]);

  return (
    <>
      <div className="section section-team" ref={sectionTeamRef}>
        <div className="container">
          <div className="team-members d-flex flex-column justify-content-center align-items-center">
            <div className="font-montserrat-extra-bold fsz-16 color-red-pink mb-7 title">
              MEET THE TEAM
            </div>
            <div className="list-members d-flex flex-wrap justify-content-space-between align-items-start">
              <div className="member member-1 d-flex justify-content-space-between align-items-start mb-3">
                <div className="member-img p-1"></div>
                <div className="box d-flex flex-column justify-content-space-between">
                  <h3 className="font-montserrat-extra-bold fsz-14 color-red-pink mb-1">
                    DUKE ALVIN LIM
                  </h3>
                  <div className="desc d-flex flex-wrap justify-content-space-between align-items-center">
                    <p className="fsz-7 font-avenir-next-regular mb-1">
                      Founder of D Cheat Code
                    </p>
                    <p className="fsz-7 font-avenir-next-regular mb-1">
                      A forex trader for over 15 years, Duke leverages on his
                      academic knowledge from Computer Science to do algorithm
                      trading. Duke ventured into crypto trading in 2018 and
                      started writing algorithms to trade professionally. His
                      vision is to create a community of traders who uses rule
                      based strategies to automate trades so that we can all
                      enjoy life as we should all be doing.
                    </p>
                  </div>
                </div>
              </div>

              <div className="member member-2 d-flex justify-content-space-between align-items-start mb-3">
                <div className="member-img p-2"></div>
                <div className="box d-flex flex-column justify-content-space-between">
                  <h3 className="font-montserrat-extra-bold fsz-14 color-red-pink mb-1">
                    ROY HUI
                  </h3>
                  <div className="desc d-flex flex-wrap justify-content-space-between align-items-center">
                    <p className="fsz-7 font-avenir-next-regular mb-1">
                      Founder of Pellar, blockchain visionary.
                    </p>
                    <p className="fsz-7 font-avenir-next-regular mb-1">
                      Roy is an engineer and a serial technopreneur, and entered
                      the blockchain arena in the early 2010s. He has
                      successfully launched over 10 NFT projects, and his
                      company, Pellar, currently processes over 10 million
                      transactions daily.
                    </p>
                  </div>
                </div>
              </div>

              <div className="member member-3 d-flex justify-content-space-between align-items-start mb-3">
                <div className="member-img p-3"></div>
                <div className="box d-flex flex-column justify-content-space-between">
                  <h3 className="font-montserrat-extra-bold fsz-14 color-red-pink mb-1">
                    THE DIGITAL GUILD
                  </h3>
                  <div className="desc d-flex flex-wrap justify-content-space-between align-items-center">
                    <p className="fsz-7 font-avenir-next-regular mb-1">
                      Project Management and Consultancy Firm
                    </p>
                    <p className="fsz-7 font-avenir-next-regular mb-1">
                      Made up of an assortment of passionate IT professionals,
                      the Digital Guild helps companies deliver the best digital
                      projects, with extensive experience in both private and
                      government sectors, as well as rock-solid experience in
                      start-up and venture building.
                    </p>
                  </div>
                </div>
              </div>

              <div className="member member-4 d-flex justify-content-space-between align-items-start mb-3">
                <div className="member-img p-4"></div>
                <div className="box d-flex flex-column justify-content-space-between">
                  <h3 className="font-montserrat-extra-bold fsz-14 color-red-pink mb-1">
                    THE PINNACLE CREATIVE PTE LTD
                  </h3>
                  <div className="desc d-flex flex-wrap justify-content-space-between align-items-center">
                    <p className="fsz-7 font-avenir-next-regular mb-1">
                      Branding and Marketing Agency.
                    </p>
                    <p className="fsz-7 font-avenir-next-regular mb-1">
                      This motley crew of creatives and digital marketing geeks
                      goes against the grain, using data-driven tactics and
                      handcrafted strategies to build sustainable results via
                      branding. Pinnacle hopes to let this project flourish and
                      bring Dude D Dough Boy to the world.
                    </p>
                  </div>
                </div>
              </div>

              <div className="member member-4 d-flex justify-content-space-between align-items-start mb-3">
                <div className="member-img p-5"></div>
                <div className="box d-flex flex-column justify-content-space-between">
                  <h3 className="font-montserrat-extra-bold fsz-14 color-red-pink mb-1">
                    D’LEDGERS CONSULTANTS PTE LTD
                  </h3>
                  <div className="desc d-flex flex-wrap justify-content-space-between align-items-center">
                    <p className="fsz-7 font-avenir-next-regular mb-1">
                      Established since 2017, D’Ledgers Consultants specializes
                      in advising promising blockchain and digital asset based
                      businesses. D’Ledgers have consulted on many projects
                      throughout the years including several sovereign
                      government-linked projects.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
