import { BrowserRouter, Route, Switch, Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ROUTERS } from "./router";
import { map } from "lodash";
import "./styles/index.sass";
import "react-toastify/dist/ReactToastify.css";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { useEffect, useState } from "react";

import { ToastContainer } from "react-toastify";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

const history = createBrowserHistory();

const getLibrary = (provider) => {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
};

function App() {
  const [isShowBackToTop, setIsShowBackToTop] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", (e) => handleScroll(e));
    return () => {
      window.removeEventListener("scroll", (e) => handleScroll(e));
    };
  }, []);

  const handleScroll = (e) => {
    if (window.scrollY > 1000) {
      setIsShowBackToTop(true);
    } else {
      setIsShowBackToTop(false);
    }
  };

  const backToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Router history={history}>
          <Web3ReactProvider getLibrary={getLibrary}>
            <div className="app-content">
              <Switch>
                {map(ROUTERS, (route, idx) => {
                  return (
                    <Route
                      exact
                      path={route.path}
                      component={route.component}
                      key={idx}
                    />
                  );
                })}
              </Switch>
              <img
                src="/images/icons/arrow-up.png"
                alt="back to top"
                title="Back to top"
                className={`back-to-top ${isShowBackToTop ? "show" : ""}`}
                onClick={() => backToTop()}
              />
            </div>
          </Web3ReactProvider>
        </Router>
      </BrowserRouter>

      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        theme="light"
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
