import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setRefAbout } from "../../../redux/actions/home.actions.js";

import "./index.sass";

export const SectionAbout = () => {
  const sectionAboutRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRefAbout(sectionAboutRef));
  }, [dispatch]);

  return (
    <>
      <div className="section section-about" ref={sectionAboutRef}>
        <div className="container">
          <div className="content d-flex flex-wrap justify-content-space-between align-items-center">
            <div className="left-text">
              <div className="font-montserrat-extra-bold fsz-16 color-red-pink mb-2">
                ABOUT DDDB
              </div>
              <div className="desc">
                <p className="font-avenir-next-regular fsz-12 mb-2">
                  Dude D Dough Boy is an exclusive Singapore-based NFT community
                  project designed to bring the dough home to you. He was
                  conceived by seasoned traders in the forex and crypto fields.
                  If you’re a trader, or aspire to be one, this little bot will
                  assist you in monitoring and trading in financial markets.
                  DDDB’s community aims to guide, support, encourage and most
                  importantly, help all of us make money together.
                </p>
                <p className="font-avenir-next-regular fsz-12 mb-2">
                  Dude D Dough Boy wears many hats. If you need a good party, he
                  knows how to rise to the occasion. If you want to get rich,
                  having him around could make you the next Elon Crust. He has
                  what you knead, and he will never give up. DDDB works hard
                  just so you can play hard, all because he sincerely believes
                  in the phrase: Dough or doughnut, there is no rye.
                </p>
                <p className="font-avenir-next-regular fsz-12 mb-2">
                  Bready or not, here he crumbs!
                </p>
              </div>
            </div>
            <div className="right-collector">
              <div className="list-item d-flex flex-wrap justify-content-space-evenly align-items-start">
                <div className="item item-1"></div>
                <div className="item item-2"></div>
                <div className="item item-3"></div>
                <div className="item item-4"></div>
                <div className="item item-5"></div>
                <div className="item item-6"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
