import React from "react";
import SwiperCore, { Autoplay, Pagination } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import { map } from "lodash";

import "./index.sass";

const LIST_IMAGES = [
  "/images/featured/400.jpg",
  "/images/featured/500.jpg",
  "/images/featured/800.jpg",
  "/images/featured/600.jpg",
  "/images/featured/700.jpg",
  "/images/featured/1000.jpg",
  "/images/featured/900.jpg",
];

SwiperCore.use([Autoplay, Pagination]);

export const SectionFeatured = () => {
  return (
    <>
      <div className="section section-featured">
        <div className="container">
          <div className="items-properties d-flex flex-column justify-content-center align-items-center">
            <div className="font-montserrat-extra-bold fsz-16 color-red-pink mb-7">
              FEATURED
            </div>
            <div className="list-items-properties d-flex flex-wrap justify-content-space-between align-items-center">
              <div className="item item-1 d-flex justify-content-space-between align-items-start mb-3">
                <div className="item-img p-1"></div>
                <div className="box d-flex flex-column justify-content-space-between">
                  <h3 className="font-montserrat-bold fsz-14 mb-3 color-red-pink">
                    THE RAINBOW BOY
                  </h3>
                  <div className="properties d-flex flex-wrap justify-content-space-between align-items-center">
                    <div className="properties-item">
                      <label className="d-block font-montserrat-regular fsz-5 mb-1 color-dove-grey">
                        Skin
                      </label>
                      <p className="font-montserrat-semi-bold fsz-3 bg-white">
                        Rainbow
                      </p>
                    </div>

                    <div className="properties-item">
                      <label className="d-block font-montserrat-regular fsz-5 mb-1 color-dove-grey">
                        Headwear
                      </label>
                      <p className="font-montserrat-semi-bold fsz-3 bg-white">
                        Coffee Mug
                      </p>
                    </div>
                    <div className="properties-item">
                      <label className="d-block font-montserrat-regular fsz-5 mb-1 color-dove-grey">
                        Arm
                      </label>
                      <p className="font-montserrat-semi-bold fsz-3 bg-white">
                        You Tiao
                      </p>
                    </div>
                    <div className="properties-item">
                      <label className="d-block font-montserrat-regular fsz-5 mb-1 color-dove-grey">
                        Hover
                      </label>
                      <p className="font-montserrat-semi-bold fsz-3 bg-white">
                        Glow
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="item item-2 d-flex justify-content-space-between align-items-start mb-3">
                <div className="item-img p-2"></div>
                <div className="box d-flex flex-column justify-content-space-between">
                  <h3 className="font-montserrat-bold fsz-14 mb-3 color-red-pink">
                    TOTO LONG QUEUE
                  </h3>
                  <div className="properties d-flex flex-wrap justify-content-space-between align-items-center">
                    <div className="properties-item">
                      <label className="d-block font-montserrat-regular fsz-5 mb-1 color-dove-grey">
                        Skin
                      </label>
                      <p className="font-montserrat-semi-bold fsz-3 bg-white">
                        Blue
                      </p>
                    </div>

                    <div className="properties-item">
                      <label className="d-block font-montserrat-regular fsz-5 mb-1 color-dove-grey">
                        Headwear
                      </label>
                      <p className="font-montserrat-semi-bold fsz-3 bg-white">
                        Toto
                      </p>
                    </div>
                    <div className="properties-item">
                      <label className="d-block font-montserrat-regular fsz-5 mb-1 color-dove-grey">
                        Arm
                      </label>
                      <p className="font-montserrat-semi-bold fsz-3 bg-white">
                        Rolling Pins
                      </p>
                    </div>
                    <div className="properties-item">
                      <label className="d-block font-montserrat-regular fsz-5 mb-1 color-dove-grey">
                        Hover
                      </label>
                      <p className="font-montserrat-semi-bold fsz-3 bg-white">
                        Water
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="item item-3 d-flex justify-content-space-between align-items-start mb-3">
                <div className="item-img p-3"></div>
                <div className="box d-flex flex-column justify-content-space-between">
                  <h3 className="font-montserrat-bold fsz-14 mb-3 color-red-pink">
                    HYPEBEAST GIRL
                  </h3>
                  <div className="properties d-flex flex-wrap justify-content-space-between align-items-center">
                    <div className="properties-item">
                      <label className="d-block font-montserrat-regular fsz-5 mb-1 color-dove-grey">
                        Skin
                      </label>
                      <p className="font-montserrat-semi-bold fsz-3 bg-white">
                        Green
                      </p>
                    </div>

                    <div className="properties-item">
                      <label className="d-block font-montserrat-regular fsz-5 mb-1 color-dove-grey">
                        Headwear
                      </label>
                      <p className="font-montserrat-semi-bold fsz-3 bg-white">
                        Toast
                      </p>
                    </div>
                    <div className="properties-item">
                      <label className="d-block font-montserrat-regular fsz-5 mb-1 color-dove-grey">
                        Arm
                      </label>
                      <p className="font-montserrat-semi-bold fsz-3 bg-white">
                        Rolling Pins
                      </p>
                    </div>
                    <div className="properties-item">
                      <label className="d-block font-montserrat-regular fsz-5 mb-1 color-dove-grey">
                        Hover
                      </label>
                      <p className="font-montserrat-semi-bold fsz-3 bg-white">
                        Hologram
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="item item-4 d-flex justify-content-space-between align-items-start mb-3">
                <div className="item-img p-4"></div>
                <div className="box d-flex flex-column justify-content-space-between">
                  <h3 className="font-montserrat-bold fsz-14 mb-3 color-red-pink">
                    BAKER GRANNY
                  </h3>
                  <div className="properties d-flex flex-wrap justify-content-space-between align-items-center">
                    <div className="properties-item">
                      <label className="d-block font-montserrat-regular fsz-5 mb-1 color-dove-grey">
                        Skin
                      </label>
                      <p className="font-montserrat-semi-bold fsz-3 bg-white">
                      Blue
                      </p>
                    </div>

                    <div className="properties-item">
                      <label className="d-block font-montserrat-regular fsz-5 mb-1 color-dove-grey">
                        Headwear
                      </label>
                      <p className="font-montserrat-semi-bold fsz-3 bg-white">
                        Chef Hat
                      </p>
                    </div>
                    <div className="properties-item">
                      <label className="d-block font-montserrat-regular fsz-5 mb-1 color-dove-grey">
                        Arm
                      </label>
                      <p className="font-montserrat-semi-bold fsz-3 bg-white">
                      Eggs
                      </p>
                    </div>
                    <div className="properties-item">
                      <label className="d-block font-montserrat-regular fsz-5 mb-1 color-dove-grey">
                        Hover
                      </label>
                      <p className="font-montserrat-semi-bold fsz-3 bg-white">
                      Water
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dddb-slider">
            <Swiper
              spaceBetween={60}
              slidesPerView={1.5}
              centeredSlides={true}
              autoplay={true}
              loop={true}
              pagination={{ clickable: true }}
              breakpoints={{
                320: {
                  slidesPerView: 1.5,
                  spaceBetween: 20,
                },
                480: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                640: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
                768: {
                  slidesPerView: 5,
                  spaceBetween: 35,
                },
              }}
            >
              {map(LIST_IMAGES, (image, idx) => {
                return (
                  <SwiperSlide key={idx}>
                    <img src={image} alt="" />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};
