import React from "react";
import "./index.sass";

export const SectionCommunity = () => {
  return (
    <>
      <div className="section section-community">
        <div className="container">
          <div className="content d-flex flex-column justify-content-center align-items-center me-auto">
            <div className="font-montserrat-extra-bold fsz-16 color-violet mb-2">
              COMMUNITY
            </div>
            <p className="text text-center font-helvetica-neue fsz-8 mb-3">
              If you want to have someone earn money for you, Dude D Dough Boy
              is your boy! Your boy might be the next Bread Pitt, or Rye’n
              Reynolds, or even Pita Parker. Our community all have their own
              DDDBs, and they enjoy having lil’ DDDB earning money for them.
              With DDDB, the best is yet to crumb.
            </p>
            <a target="_blank" href="https://discord.gg/dddb">
              <button className="btn-gradient font-montserrat-bold fsz-2">
                  JOIN COMMUNITY
              </button>
            </a>
            <br/>
            <a target="_blank" href="https://dddb-assets.s3.ap-southeast-1.amazonaws.com/docs/DDDBLitePaper.pdf">
              <button className="btn-gradient font-montserrat-bold fsz-2">
                Download Deck
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
