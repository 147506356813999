export const APP_ENVIRONMENTS = {
  SUPPORTED_CHAIN_IDS: Number(process.env.REACT_APP_SUPPORTED_CHAIN_IDS || 1),
  DEFAULT_NETWORK: String(process.env.REACT_APP_DEFAULT_NETWORK || "mainnet"),
  // SALE_STATUS: String(process.env.REACT_APP_SALE_STATUS || "private_sale"),

  CONTRACTS_ADDRESS: String(
    process.env.REACT_APP_CONTRACTS ||
      "0xF5A3E091D6daAe9029E113C2868866233cbF5D05"
  ),
  MT4_API_URL: String(
    process.env.REACT_APP_MT4_API_URL || "https://services.pellar.io/api/v1/dddb"
  ),
};
