import React, { useState } from "react";
import { map } from "lodash";
import "./index.sass";

const QUESTIONS = [
  {
    question: "What is DDDB?",
    answer:
      "Created by seasoned traders in the forex and crypto field, Dude D Dough Boy (DDDB) NFT is an exclusive NFT community project targeting traders of all levels. We want to create a community of algorithm traders that support, guide, encourage and more importantly, make money together. <br /><br />We want to help you bring the dough home. ",
  },
  {
    question: "What is a NFT?",
    answer:
      "Non-Fungible Tokens are unique, easily verifiable digital assets that can represent items such as GIFs, images, videos, music albums, and more. Anything that exists online can be purchased as an NFT, theoretically, and can have various benefits. They can be treated like membership cards where holders get exclusive access to things like websites, events, merchandise and more. ",
  },
  {
    question: "What are we trying to achieve?",
    answer:
      "Every DDDB NFT holder will gain an exclusive MetaTrader (MT) 4 Expert Advisory (EA) Program. This program will allow them to: <br /><br /><ul><li class='font-avenir-next-regular fsz-10'>Eliminate Trader’s Emotion</li><li class='font-avenir-next-regular fsz-10'>Better time entries and exits</li><li class='font-avenir-next-regular fsz-10'>Provide a systematic trading</li></ul>",
  },
  {
    question: "What is DDDB Utility?",
    answer:
      'By owning a DDDB NFT, you are entitled to: <br/><br/><ul><li class="font-avenir-next-regular fsz-10">Exclusive MetaTrader 4 (MT4) Expert Advisor (EA) which can auto trade on your behalf with a 3 months expiry renewable as long as you are a proud DDDB NFT holder</li><li class="font-avenir-next-regular fsz-10">Access to an exclusive community of traders to help you to share and learn together within the D Cheat Code Ecosystem</li><li class="font-avenir-next-regular fsz-10">Curated knowledge base that aggregates trading contents that will accelerate your learning.</li><li class="font-avenir-next-regular fsz-10">Trading signals that will help you to be able to time your entry and exit better.</li></ul>',
  },
  {
    question: "What is a MT4 EA Program?",
    answer:
      "Expert Advisors (EAs) are programs that run on the MetaTrader 4 (MT4) platform, used to monitor and trade financial markets using algorithms. They find opportunities according to the parameters you set, then either notify you or open a position automatically. <br /><br />One of the DDDB MT4 EA, 2MA EA uses 2 Moving Averages as basis to enter and exit the trades. This simple yet effect way of identifying a trend if used on BTC on a Daily chart with certain settings would have resulted in 18/18 winning trades over a 3-year period (2019 – 2021)",
  },
  {
    question: "What is DDDB Supply and our launch?",
    answer:
      "We are planning to launch on 18 April 2022 for a total supply of 10,000 DDDB NFTs. <br /><br />Do note that we will NEVER have a stealth-launch, please be careful of scams. Official launch details will be shared on our website, discord and our twitter page. ",
  },
  {
    question: "How to buy (Mint) a DDDB NFT?",
    answer:
      'Be one of the exclusive whitelisted to gain access to DDDB NFT. For more information, wait for the next update on 23 March 2022 <br /><br />Install Metamask extension on your browser and link your wallet and go to <a class="font-avenir-next-regular fsz-10" href="http://dddb.club">http://dddb.club</a>. Ensure you have enough ETH in your wallet to cover the cost and the gas fee. ',
  },
];

export const SectionFaq = () => {
  const [listShowQuestion, setListShowQuestion] = useState([]);

  return (
    <>
      <div className="section section-faq full-page bg-red-pink position-relative">
        <div className="container">
          <img
            src="/images/faq-text-mask.png"
            alt=""
            className="faq-text-mark position-absolute"
          />
          <div className="content">
            <div className="title-header">
              <h3 className="title font-montserrat-black fsz-20 color-yellow">
                QUESTIONS
              </h3>
              <h3 className="sub-title font-avenir-next-regular fsz-17 color-white mb-7">
                FAQ
              </h3>
            </div>
            <div className="list-quetions d-flex justify-content-space-between align-items-start">
              <div className="question-content">
                {map(QUESTIONS, (el, idx) => {
                  return (
                    <div className="list" key={idx}>
                      <h3
                        className={`font-montserrat-black fsz-12 mb-3 title ${
                          listShowQuestion.includes(idx) ? "color-yellow" : ""
                        }`}
                        onClick={() => {
                          let listId = [...listShowQuestion];
                          const index = listId.indexOf(idx);
                          if (index > -1) {
                            listId.splice(index, 1);
                          } else {
                            listId.push(idx);
                          }
                          setListShowQuestion(listId);
                        }}
                      >
                        {el.question}
                      </h3>
                      <p
                        className={`font-avenir-next-regular fsz-10 detail ${
                          listShowQuestion.includes(idx) ? "show" : "none"
                        }`}
                        dangerouslySetInnerHTML={{ __html: el.answer }}
                      >
                        {/* {el.answer} */}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
