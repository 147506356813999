import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDiscord,
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import "./index.sass";
import { toast } from "react-toastify";
import { CONSTANTS, NETWORK_DATA, SALE_STATUS } from "../../configs/constants";
import { useWeb3React } from "@web3-react/core";
import { APP_ENVIRONMENTS } from "../../configs/env";
import { formatAccountDisplay } from "../../helpers";
import { injected } from "../../connectors";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Contract } from "ethers";
import { ABI_ARRAY } from "../../contracts/contract";
import { setBalanceToken } from "../../redux/actions/home.actions";

export const Header = ({ isHiddenMenu }) => {
  const [isOpenMenuMobile, setIsOpenMenuMobile] = useState(false);
  const { refAbout, refRoadmap, refTeam } = useSelector((state) => state.home);
  const web3ReactContext = useWeb3React();
  const { account, chainId, activate, active, error, library } =
    web3ReactContext;
  const networkStatus = NETWORK_DATA[APP_ENVIRONMENTS.DEFAULT_NETWORK];

  const myContract = useMemo(() => {
    if (library) {
      return new Contract(
        APP_ENVIRONMENTS.CONTRACTS_ADDRESS,
        ABI_ARRAY,
        library.getSigner()
      );
    }
  }, [library]);

  const dispatch = useDispatch();
  const { tokenBalance } = useSelector((state) => state.home);

  const scrollToPosition = (ref) => window.scrollTo(0, ref.current.offsetTop);

  const executeScroll = (sectionName) => {
    switch (sectionName) {
      case "top":
        return scrollToPosition(0);
      case "about":
        return scrollToPosition(refAbout);
      case "roadmap":
        return scrollToPosition(refRoadmap);
      case "team":
        return scrollToPosition(refTeam);
      default:
        break;
    }
  };

  const handleMenuClick = (event, sectionName) => {
    event.preventDefault();

    if (isTablet || isMobile) {
      setIsOpenMenuMobile(!isOpenMenuMobile);
    }
    executeScroll(sectionName);
  };

  const handleConnectWallet = async () => {
    const { ethereum } = window;

    if (isTablet || isMobile) {
      if (ethereum && ethereum.isMetaMask) {
        console.log(true);
      } else {
        return window.open("https://metamask.app.link/dapp/dddb.club");
      }
    }

    if (account) {
      return;
    }

    if (!ethereum) {
      return toast.error(CONSTANTS.messageToast.error_metamask_plugin);
    }

    if (error) {
      if (error.code === -32002) {
        return toast.error(error.message);
      }

      if (chainId !== networkStatus.chainId) {
        return toast.error(networkStatus.messageErr);
      }
    }

    // If the provider is not detected, we will check this case
    const ethChainId = await window.ethereum.request({
      method: "eth_chainId",
    });

    if (ethChainId !== networkStatus.ethChainId) {
      return toast.error(networkStatus.messageErr);
    }

    try {
      activate(injected);
    } catch (error) {
      console.log("error", error);
    }
  };

  // Default auto connect network
  useEffect(() => {
    injected
      .isAuthorized()
      .then((isAuthorized) => {
        if (isAuthorized && !active && !error) {
          activate(injected);
        }
      })
      .catch(() => {});
  }, [activate, active, error]);

  useEffect(() => {
    if (account) {
      getBalance();
    }
  }, [account]);

  const getBalance = async () => {
    try {
      const balance = await myContract.balanceOf(account);
      dispatch(setBalanceToken(Number(balance)));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="header bg-red-pink">
      <div className="wrapper">
        <div className="header-top">
          <div className="left-box">
            <div className="logo" data-aos="flip-down">
              <Link to="/" title="Home page">
                <img src="/images/logo.png" alt="" />
              </Link>
            </div>
          </div>

          <ul
            className={`menu ${isOpenMenuMobile ? "show" : "hide"} ${
              isHiddenMenu ? "hidden" : ""
            }`}
          >
            <li className="menu-item">
              <Link
                to="/"
                className="link menu-link"
                onClick={(e) => handleMenuClick(e, "about")}
              >
                About DDDB
              </Link>
            </li>
            <li className="menu-item">
              <Link
                to="/"
                className="link menu-link"
                onClick={(e) => handleMenuClick(e, "roadmap")}
              >
                Roadmap
              </Link>
            </li>
            <li className="menu-item">
              <Link
                to="/"
                className="link menu-link"
                onClick={(e) => handleMenuClick(e, "team")}
              >
                Team
              </Link>
            </li>
            <li className="menu-item download-mobile">
              <a
                href="https://dddb-assets.s3.ap-southeast-1.amazonaws.com/docs/DDDBLitePaper.pdf"
                className="link menu-link"
                target="_blank"
              >
                Download Deck
              </a>
            </li>
            {tokenBalance > 0 && (
              <li className="menu-item dddb-member-mobile">
                <Link
                  to={"/member"}
                  className="link menu-link"
                  onClick={(e) => setIsOpenMenuMobile(!isOpenMenuMobile)}
                >
                  DDDB Member
                </Link>
              </li>
            )}

            <li className="menu-item download-mobile">
              {library ? (
                <span className="link menu-link color-yellow" target="_blank">
                  {formatAccountDisplay(account)}
                </span>
              ) : (
                <span
                  className="link menu-link color-yellow"
                  target="_blank"
                  onClick={() => handleConnectWallet()}
                >
                  CONNECT WALLET
                </span>
              )}
            </li>
          </ul>

          <ul className="social-menu d-flex justify-content-center align-items-start">
            <li>
              <a
                href="https://discord.gg/dddb"
                target="_blank"
                title="Discord"
                data-aos="flip-up"
                data-aos-delay="600"
              >
                <FontAwesomeIcon icon={faDiscord} />
              </a>
            </li>

            <li>
              <a
                href="https://twitter.com/dddbnft"
                target="_blank"
                title="Twitter"
                data-aos="flip-up"
                data-aos-delay="560"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/DDDB-101448949168069/"
                target="_blank"
                title="Facebook"
                data-aos="flip-up"
                data-aos-delay="500"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/dddbnft/"
                target="_blank"
                title="Instagram"
                data-aos="flip-up"
                data-aos-delay="520"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
          </ul>

          <div
            className={`mobile-trigger ${isOpenMenuMobile ? "open" : ""} ${
              isHiddenMenu ? "hidden" : ""
            }`}
            onClick={() => setIsOpenMenuMobile(!isOpenMenuMobile)}
          >
            <img
              src="/images/icons/menu.svg"
              alt="open menu"
              className="open-menu"
            />
            <img
              src="/images/icons/close.svg"
              alt="close menu"
              className="close-menu"
            />
          </div>

          {library ? (
            <button className="btn-gradient btn-connect-wallet font-montserrat-bold fsz-2">
              {formatAccountDisplay(account)}
            </button>
          ) : (
            <button
              className="btn-gradient btn-connect-wallet font-montserrat-bold fsz-2"
              onClick={() => handleConnectWallet()}
            >
              CONNECT WALLET
            </button>
          )}

          {tokenBalance > 0 && (
            <Link to={"/member"} title="Member" className="member-area">
              <FontAwesomeIcon icon={faUser} />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
