import { Link } from "react-router-dom";
import "./index.sass";

export const Footer = (props) => {
  return (
    <div className="footer d-flex justify-content-center align-items-center">
      <div className="container">
        <div className="content d-flex justify-content-space-between align-items-center">
          <Link to="/" title="" className="logo">
            <img src="/images/logo.png" alt="" />
          </Link>
          <ul className="footer-menu d-flex justify-content-space-between align-items-center">
            <li>
              <a href = "mailto: admin@dddb.club" className="font-montserrat-extra-bold fsz-7">
                Contact Us
              </a>
            </li>
            <li>
              <a target="_blank" href="https://dddb-assets.s3.ap-southeast-1.amazonaws.com/docs/terms.pdf" className="font-montserrat-extra-bold fsz-7">
                Terms
              </a>
            </li>
            <li>
              <a target="_blank" href="https://dddb-assets.s3.ap-southeast-1.amazonaws.com/docs/privacy.pdf" className="font-montserrat-extra-bold fsz-7">
                Privacy
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
