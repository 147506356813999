import { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import "./index.sass";

export function MessageClaimBox({
  isOpenClaimBox,
  status,
  msg,
  transactionHash,
  close,
}) {
  const strTransactionHash = (transactionHash) => {
    return `${transactionHash?.substr(0, 10)}...${transactionHash?.substr(
      -10,
      10
    )}`;
  };

  const renderJSX = useMemo(() => {
    return (
      <>
        {isOpenClaimBox ? (
          <div className="message-claim-box font-montserrat-semi-bold fsz-6 color-white">
            <span className="close-message font-montserrat-semi-bold" onClick={close}>
              x
            </span>
            {status === "sending" && "Minting..."}

            {status === "success" && (
              <>
                <p className="font-montserrat-semi-bold fsz-6 color-yellow">
                  Success! <br />
                  Transaction Hash:{" "}
                  <a
                    className="truncate font-avenir-next-regular fsz-6 color-white"
                    href={`https://etherscan.io/tx/${transactionHash}`}
                    target="_blank"
                  >
                    {strTransactionHash(transactionHash)}
                  </a>
                </p>
              </>
            )}

            {status === "error" && msg}
          </div>
        ) : (
          ""
        )}
      </>
    );
  }, [isOpenClaimBox, status, msg, transactionHash, close]);

  return <>{renderJSX}</>;
}
